import { filter, indexOf, valuesIn } from 'lodash-es'
import * as yup from 'yup'

import { transformToNumber } from '@/Utilities/Validation/Transforms/ToNumber'

const setSchema = yup.object().shape({
  name: yup.string()
    .label('Name')
    .required()
    .min(3)
    .max(50)
    .meta({ tabKey: 'general' }),

  description: yup.string()
    .label('Description')
    .max(250)
    .nullable()
    .meta({ tabKey: 'general' }),

  siteId: yup.mixed()
    .label('Site')
    .populatedObject()
    .meta({ tabKey: 'general' }),

  blocks: yup.mixed()
    .label('Irrigation blocks')
    .meta({ tabKey: 'general' }),

  flowFailureTolerance: yup.number()
    .label('Flow failure tolerance')
    .nullable()
    .required()
    .min(0)
    .max(10)
    .transform(transformToNumber)
    .meta({ tabKey: 'general' }),

  stabilizationTimeUnitOfMeasurement: yup.mixed()
    .label('Flow stability time unit of measure')
    .populatedObject()
    .meta({ tabKey: 'general' }),

  stabilizationTime: yup.number()
    .label('Flow stability time')
    .nullable()
    .required()
    .min(0)
    .transform(transformToNumber)
    .meta({ tabKey: 'general' }),

  scheduleType: yup.mixed()
    .label('Schedule type')
    .populatedObject()
    .meta({ tabKey: 'timing' }),

  intervalBetweenIrrigationDays: yup.number()
    .nullable()
    .label('Interval between irrigation days')
    .when('scheduleType', ([scheduleType], schema) => {
      if (scheduleType?.value !== 'interval') {
        return schema
      }

      return schema.min(1).max(30).required()
    })
    .transform(transformToNumber)
    .meta({ tabKey: 'timing' }),

  schedulerNumberOfWeeks: yup.mixed()
    .label('Weeks')
    .when('scheduleType', ([scheduleType], schema) => {
      if (scheduleType?.value !== 'days') {
        return schema.nullable()
      }

      return schema.populatedObject()
    })
    .meta({ tabKey: 'timing' }),

  startDate: yup.string()
    .label('Date range start')
    .when('scheduleType', ([scheduleType], schema) => {
      if (scheduleType?.value !== 'days') {
        return schema.nullable()
      }

      return schema.required()
    })
    .meta({ tabKey: 'timing' }),

  endDate: yup.string()
    .label('Date range end')
    .when('scheduleType', ([scheduleType], schema) => {
      if (scheduleType?.value !== 'days') {
        return schema.nullable()
      }

      return schema.required()
    })
    .meta({ tabKey: 'timing' }),

  schedule: yup.mixed()
    .label('Schedule')
    .when('scheduleType', ([scheduleType], schema) => {
      if (scheduleType?.value !== 'days') {
        return schema.nullable()
      }

      return schema.populatedObject().test(
        'schedule-required',
        'Schedule is a required field',
        (value) => {
          let validWeeks = filter(value, (week) => {
            return (indexOf(valuesIn(week), 'water') >= 0) || (indexOf(valuesIn(week), 'waterAndFert') >= 0)
          })

          return !!validWeeks.length
        },
      )
    })
    .meta({ tabKey: 'timing' }),

  stopTimeDisabled: yup.boolean()
    .meta({ tabKey: 'timing' }),

  startTimeHours: yup.number()
    .label('Start time hours')
    .min(0)
    .max(23)
    .required()
    .meta({ tabKey: 'timing' }),

  startTimeMinutes: yup.number()
    .label('Start time minutes')
    .min(0)
    .max(59)
    .required()
    .meta({ tabKey: 'timing' }),

  stopTimeHours: yup.number()
    .label('Stop time hours')
    .min(0)
    .max(23)
    .when('stopTimeDisabled', ([stopTimeDisabled], schema) => {
      if (stopTimeDisabled) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.required()
    })
    .meta({ tabKey: 'timing' }),

  stopTimeMinutes: yup.number()
    .label('Stop time minutes')
    .when('stopTimeDisabled', ([stopTimeDisabled], schema) => {
      if (stopTimeDisabled) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.min(0).max(59).required()
    })
    .meta({ tabKey: 'timing' }),

  irrigationBasis: yup.mixed()
    .label('Irrigation basis')
    .populatedObject()
    .meta({ tabKey: 'timing' }),

  numberOfCycles: yup.number()
    .label('Number of cycles')
    .nullable()
    .when(['infiniteCycles'], ([infiniteCycles], schema) => {
      if (infiniteCycles) {
        return schema.nullable()
      }

      return schema.min(1).max(100000).required()
    })
    .transform(transformToNumber)
    .meta({ tabKey: 'timing' }),

  intervalBetweenCyclesHours: yup.number()
    .label('Interval hours')
    .when(['numberOfCycles', 'infiniteCycles'], ([numberOfCycles, infiniteCycles], schema) => {
      if (numberOfCycles == 1 || infiniteCycles) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.min(0).max(23).required()
    })
    .meta({ tabKey: 'timing' }),

  intervalBetweenCyclesMinutes: yup.number()
    .label('Interval hours')
    .when(['numberOfCycles', 'infiniteCycles'], ([numberOfCycles, infiniteCycles], schema) => {
      if (numberOfCycles == 1 || infiniteCycles) {
        return schema.transform(transformToNumber).nullable()
      }

      return schema.min(0).max(59).required()
    }),

  intervalBetweenCyclesSeconds: yup.number().label('Interval hours').when(['numberOfCycles', 'infiniteCycles'], ([numberOfCycles, infiniteCycles], schema) => {
    if (numberOfCycles == 1 || infiniteCycles) {
      return schema.transform(transformToNumber).nullable()
    }

    return schema.min(0).max(59).required()
  }),
})

export default setSchema
