import { compact, isEmpty, join, map, startCase } from 'lodash-es'

const typeMap = { inputOutputs: 'I/Os' }

const FormatDisabledReason = ({
  programNames,
  currentAssociations,
}) => {
  const disabledProgramReason = programNames && (
    <div>
      Programs currently running: {programNames}
    </div>
  )

  const disabledAssociationReason = !isEmpty(currentAssociations) && (
    <>
      <div className="mb-1">
        This element is associated to the following:
      </div>

      <ul className="ml-5 list-disc">
        {map(currentAssociations, (name, type) => {
          return (
            <li key={type}>
              {typeMap[type] || startCase(type)}: {join(name, ', ')}
            </li>
          )
        })}
      </ul>
    </>
  )

  if (!disabledProgramReason && !disabledAssociationReason) {
    return null
  }

  const disabledReasons = compact([disabledProgramReason && 'in use by an active program', disabledAssociationReason && 'associated to other elements'])

  return (
    <div>
      <div className="mb-2">
        This item is disabled because it is {join(disabledReasons, ' or ')}.
      </div>

      {disabledProgramReason && (
        <div className="mb-2">
          {disabledProgramReason}
        </div>
      )}

      {disabledAssociationReason && (
        <div>
          {disabledAssociationReason}
        </div>
      )}
    </div>
  )
}

const getDisabledWithReason = (programNames, currentAssociations) => {
  if (!isEmpty(programNames) || !isEmpty(currentAssociations)) {
    return <FormatDisabledReason
      programNames={programNames}
      currentAssociations={currentAssociations}
    />
  }

  return null
}

export { FormatDisabledReason, getDisabledWithReason }
