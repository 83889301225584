import 'air-datepicker/air-datepicker.css'

import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'
import { get } from 'lodash-es'
import { forwardRef, useCallback, useEffect, useRef } from 'react'

import Input from '@/Components/form/Input'

const DateTimePicker = forwardRef((props, formRef) => {
  const {
    onChange,
    options,
    minDate,
    maxDate,
  } = props
  const datePickerRef = useRef(null)
  const datePickerInstance = useRef(null)

  function formatDate(date) {
    if (!date) return ''

    let day = date.getDate().toString().padStart(2, '0')
    let month = (date.getMonth() + 1).toString().padStart(2, '0')
    let year = date.getFullYear()
    let hours = date.getHours().toString().padStart(2, '0')
    let minutes = date.getMinutes().toString().padStart(2, '0')

    if (get(options, 'timepicker', false)) {
      return `${month}-${day}-${year} ${hours}:${minutes}`
    } else {
      return `${month}-${day}-${year}`
    }
  }

  const handleDateChange = (date) => {
    onChange(formatDate(date))
  }

  // Callback ref to set both datePickerRef and formRef.
  // Workaround for having to use multiple refs.
  const setRefs = useCallback((node) => {
    datePickerRef.current = node

    if (typeof formRef === 'function') {
      formRef(node)
    }
  }, [formRef])

  useEffect(() => {
    if (datePickerRef.current && !datePickerInstance.current) {
      datePickerInstance.current = new AirDatepicker(datePickerRef.current, {
        ...options,
        locale: localeEn,
        buttons: ['clear'],
        onSelect: ({ date }) => {
          handleDateChange(date)
        },
      })
    }

    return () => {
      if (datePickerInstance.current) {
        datePickerInstance.current.destroy()
        datePickerInstance.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (datePickerInstance.current) {
      datePickerInstance.current.update({
        minDate: minDate,
        maxDate: maxDate,
      })
    }
  }, [minDate, maxDate])

  return (
    <Input ref={setRefs} {...props} />
  )
})

export default DateTimePicker
