import classNames from 'classnames'
import { find, first, get, isBoolean, isUndefined, split } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import AlertContent from '@/Components/alerts/AlertContent'
import DateTimePicker from '@/Components/form/DateTimePicker'
import DayPicker from '@/Components/form/DayPicker'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import InputLabel from '@/Components/form/InputLabel'
import LightSwitch from '@/Components/form/LightSwitch'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'

const GroupedInputs = styled.div`
  align-items: center;
  display: flex;

  input {
    margin-right: 20px;
  }
`

const TimeSeperator = styled.div`
  margin: 0 10px 0 -10px;
`

export default function Timing(props) {
  const {
    control,
    watch,
    setValue,
    trigger,
    register,
    errors,
    dirtyFields,
    getValues,
  } = useFormContext()

  const scheduleType = watch('scheduleType')
  const schedule = watch('schedule')
  const numberOfWeeks = watch('schedulerNumberOfWeeks')
  const stopTimeDisabled = watch('stopTimeDisabled')
  const infiniteCycles = watch('infiniteCycles')
  const numberOfCycles = watch('numberOfCycles')
  const selectedIrrigationBasis = watch('irrigationBasis')
  const startDate = watch('startDate')
  const endDate = watch('endDate')

  let programSet

  if (props.data?.programSet) {
    programSet = {
      ...props.data?.programSet,
      schedule: props.data?.programSet?.schedule,
    }
  }

  const { scheduleTypeOptions } = props

  const times = useMemo(() => {
    if (programSet) {
      return {
        startTime: split(programSet.startTime, ':'),
        stopTime: split(programSet.stopTime, ':'),
        intervalBetweenCycles: split(programSet.intervalBetweenCycles, ':'),
      }
    }

    return null
  }, [programSet])

  const irrigationBasisOptions = useMemo(() => {
    return [{
      value: 'time',
      label: 'Time',
    }, {
      value: 'quantity',
      label: 'Quantity',
    }]
  }, [])

  const schedulerNumberOfWeeks = useMemo(() => {
    return [
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 4,
        label: '4',
      },
    ]
  }, [])

  const getBooleanValue = useCallback((programSet, key, defaultValue = true) => {
    if (isBoolean(programSet?.[key])) {
      return programSet[key]
    }

    return defaultValue
  }, [])

  useEffect(() => {
    const currentTab = document.getElementById('timing')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          timing: !!currentTab?.querySelector('.error-message'),
        }
      })
    }
  }, [errors, props.setTabErrors])

  useEffect(() => {
    if (numberOfCycles == 1) {
      setValue('intervalBetweenCyclesHours', null)
      setValue('intervalBetweenCyclesMinutes', null)
      setValue('intervalBetweenCyclesSeconds', null)
    }
  }, [numberOfCycles])

  const addOneMonth = (date) => {
    const result = new Date(date)
    result.setMonth(result.getMonth() + 1)
    return result
  }

  const subtractOneMonth = (date) => {
    if (!date) return null
    const result = new Date(date)
    result.setMonth(result.getMonth() - 1)
    return result
  }

  const isValidDate = (date) => {
    return date && !isNaN(new Date(date).getTime())
  }

  const [validStartDate, validEndDate] = useMemo(() => {
    return [!isUndefined(startDate) && isValidDate(startDate), !isUndefined(endDate) && isValidDate(endDate)]
  }, [startDate, endDate])

  const maxDateForStartDate = validEndDate ? new Date(endDate) : addOneMonth(new Date())
  const minDateForStartDate = validEndDate ? subtractOneMonth(new Date(endDate)) : ''
  const minDateForEndDate = validStartDate ? new Date(startDate) : ''
  const maxDateForEndDate = validStartDate ? addOneMonth(new Date(startDate)) : ''

  const [formattedStartDate, formattedEndDate] = useMemo(() => {
    let startDate = get(programSet, 'startDate')
    let endDate = get(programSet, 'endDate', moment().add(7, 'day').format('MM-DD-YYYY'))

    startDate = startDate ? moment(startDate).format('MM-DD-YYYY') : moment().format('MM-DD-YYYY')
    endDate = endDate ? moment(endDate).format('MM-DD-YYYY') : moment().add(7, 'day').format('MM-DD-YYYY')

    return [startDate, endDate]
  }, [programSet])

  return (
    <>
      <Controller
        control={control}
        defaultValue={find(scheduleTypeOptions, ['value', programSet?.scheduleType]) || first(scheduleTypeOptions)}
        name="scheduleType"
        render={({ field }) => {
          return (
            <Select
              {...field}
              isMulti={false}
              isSearchable={false}
              label="Schedule type"
              isRequired={true}
              options={scheduleTypeOptions}
              placeholder="Search"
              afterChange={(option) => {
                if (option.value === 'interval') {
                  setValue('schedule', null)
                }

                if (option.value === 'days') {
                  setValue('intervalBetweenIrrigationDays', null)
                }
              }}
              hasError={!!errors.scheduleType}
            />
          )
        }}
      />
      {errors.scheduleType && <InputError message={errors.scheduleType.message} />}

      <div className={classNames({ hidden: scheduleType?.value != 'interval' })}>
        <Input
          label="Interval between irrigation days"
          type="number"
          className={errors.intervalBetweenIrrigationDays && 'error'}
          {...register('intervalBetweenIrrigationDays', { value: programSet?.intervalBetweenIrrigationDays })}
          min="1"
          max="100"
        />
        {errors.intervalBetweenIrrigationDays && <InputError message={errors.intervalBetweenIrrigationDays.message} />}
      </div>

      <div className="row">
        <div className="col-4">
          <>
            <Controller
              control={control}
              defaultValue={find(schedulerNumberOfWeeks, ['value', get(programSet, 'schedule.length')]) || first(schedulerNumberOfWeeks)}
              name="schedulerNumberOfWeeks"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={false}
                    label="Weeks"
                    isRequired={true}
                    options={schedulerNumberOfWeeks}
                    hasError={!!errors.schedulerNumberOfWeeks}
                  />
                )
              }}
            />
            {errors.schedulerNumberOfWeeks && <InputError message={errors.schedulerNumberOfWeeks.message} />}
          </>

          <>
            <div>
              <DateTimePicker
                {...register('startDate', { value: formattedStartDate })}
                label={<>Date range start <RequiredAsterisk /></>}
                name="startDate"
                onChange={(value) => {
                  setValue('startDate', value)
                }}
                options={{
                  dateFormat: 'MM-dd-yyyy',
                  autoClose: true,
                  timepicker: false,
                  selectedDates: [formattedStartDate],
                }}
                className={errors.startDate && 'error'}
                maxDate={maxDateForStartDate}
                minDate={minDateForStartDate}
              />
              {errors.startDate && <InputError message={errors.startDate.message} />}
            </div>

            <div>
              <DateTimePicker
                {...register('endDate', { value: formattedEndDate })}
                label={<>Date range end <RequiredAsterisk /></>}
                name="endDate"
                onChange={(value) => {
                  setValue('endDate', value)
                }}
                options={{
                  dateFormat: 'MM-dd-yyyy',
                  autoClose: true,
                  timepicker: false,
                  selectedDates: [formattedEndDate],
                }}
                className={errors.endDate && 'error'}
                maxDate={maxDateForEndDate}
                minDate={minDateForEndDate}
              />
              {errors.endDate && <InputError message={errors.endDate.message} />}
            </div>
          </>
        </div>

        <div className={classNames('col-8', { hidden: scheduleType?.value != 'days' && !numberOfWeeks })}>
          <InputLabel>
            Schedule
            <RequiredAsterisk />
          </InputLabel>

          <div className="rounded border p-6">
            <DayPicker
              name="schedule"
              placeholder="Select irrigation days"
              onChange={(schedule, shouldValidate = false) => {
                setValue('schedule', schedule, { shouldValidate: shouldValidate })
              }}
              rules={{ required: true }}
              indexCount={get(numberOfWeeks, 'value', 1)}
              externalRef={schedule}
              initialSchedule={programSet?.schedule || getValues('schedule')}
              className="max-w-96"
            />
          </div>
          {errors.schedule && <InputError message={errors.schedule.message} />}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-4">
          <InputLabel>
            Start time
            <RequiredAsterisk />
          </InputLabel>

          <GroupedInputs>
            <Input
              placeholder="hh"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.startTimeHours && 'error'}
              {...register('startTimeHours', {
                value: times?.startTime[0] || '',
                onChange: () => {
                  if (dirtyFields.stopTimeHours || dirtyFields.stopTimeMinutes) {
                    trigger('stopTimeHours')
                    trigger('stopTimeMinutes')
                  }
                },
              })}
            />

            <TimeSeperator>
              :
            </TimeSeperator>

            <Input
              placeholder="mm"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.startTimeMinutes && 'error'}
              {...register('startTimeMinutes', {
                value: times?.startTime[1] || '',
                onChange: () => {
                  if (dirtyFields.stopTimeHours || dirtyFields.stopTimeMinutes) {
                    trigger('stopTimeHours')
                    trigger('stopTimeMinutes')
                  }
                },
              })}
            />
          </GroupedInputs>

          {
            (
              (
                errors.startTimeHours?.type === 'min' ||
                errors.startTimeHours?.type === 'max' ||
                errors.startTimeMinutes?.type === 'min' ||
                errors.startTimeMinutes?.type === 'max'
              ) &&
              <InputError message="Start time should be between 00:00 and 23:59" />
            ) ||
            (
              (errors.startTimeHours || errors.startTimeMinutes) &&
              <InputError message="Start time is a required field" />
            )
          }
        </div>

        <div className="col-12 col-md-5">
          <InputLabel>
            Stop time
            {!stopTimeDisabled && <RequiredAsterisk />}
          </InputLabel>

          <div className="grid grid-cols-2">
            <div>
              <GroupedInputs>
                <Input
                  placeholder="hh"
                  maxlength="2"
                  type="number"
                  width="60px"
                  textCenter
                  disabled={stopTimeDisabled}
                  className={errors.stopTimeHours && 'error'}
                  {...register('stopTimeHours', {
                    value: !getBooleanValue(programSet, 'stopTimeDisabled') ? get(times, 'stopTime[0]', '') : '',
                    onChange: () => {
                      if (dirtyFields.stopTimeMinutes) {
                        trigger('stopTimeMinutes')
                      }
                    },
                  })}
                />

                <TimeSeperator>
                  :
                </TimeSeperator>

                <Input
                  placeholder="mm"
                  maxlength="2"
                  type="number"
                  width="60px"
                  textCenter
                  disabled={stopTimeDisabled}
                  className={errors.stopTimeMinutes && 'error'}
                  {...register('stopTimeMinutes', {
                    value: !getBooleanValue(programSet, 'stopTimeDisabled') ? get(times, 'stopTime[1]', '') : '',
                    onChange: () => {
                      if (dirtyFields.stopTimeHours) {
                        trigger('stopTimeHours')
                      }
                    },
                  })}
                />
              </GroupedInputs>

              {
                (
                  (
                    errors.stopTimeHours?.type === 'min' ||
                    errors.stopTimeHours?.type === 'max' ||
                    errors.stopTimeMinutes?.type === 'min' ||
                    errors.stopTimeMinutes?.type === 'max'
                  ) &&
                  <InputError message="Stop time should be between 00:00 and 23:59" />
                ) ||
                (
                  (errors.stopTimeHours?.type === 'stopAfterStart' || errors.stopTimeMinutes?.type === 'stopAfterStart') &&
                  <InputError message="Stop time must be after start time" />
                ) ||
                (
                  (errors.stopTimeHours || errors.stopTimeMinutes) &&
                  <InputError message="Stop time is a required field" />
                )
              }
            </div>

            <div className="ml-4 flex items-start">
              <LightSwitch
                wrapperClassNames="mt-2"
                label="Disable"
                onToggle={(name, value) => {
                  setValue(name, value)

                  if (value) {
                    setValue('stopTimeHours', '', { shouldValidate: true })
                    setValue('stopTimeMinutes', '', { shouldValidate: true })
                  }
                }}
                defaultState={getBooleanValue(programSet, 'stopTimeDisabled')}
                {...register('stopTimeDisabled', { value: getBooleanValue(programSet, 'stopTimeDisabled') })}
              />
            </div>
          </div>
        </div>
      </div>

      <Controller
        control={control}
        defaultValue={find(irrigationBasisOptions, ['value', programSet?.irrigationBasis])}
        name="irrigationBasis"
        render={({ field }) => {
          return (
            <Select
              {...field}
              isMulti={false}
              isSearchable={true}
              label="Irrigation basis"
              isRequired={true}
              options={irrigationBasisOptions}
              placeholder="Search"
              hasError={!!errors.irrigationBasis}
            />
          )
        }}
      />
      {errors.irrigationBasis && <InputError message={errors.irrigationBasis.message} />}

      {(programSet?.irrigationBasis && programSet?.irrigationBasis != selectedIrrigationBasis?.value) && (
        <AlertContent type="warning" hideIcon={false} className="mt-2">
          By changing the irrigation basis, this will affect all programs that belong to this program set.
          Configuration will be required on each program in this program set, in addition, the program will be disabled.
          Once you've updated the required fields on each program, you can re-enable the program to resume operation.
        </AlertContent>
      )}

      <div className="grid grid-cols-2">
        <div>
          <Input
            label="Number of cycles"
            isRequired={!infiniteCycles ? true : false}
            type="number"
            className={errors.numberOfCycles && 'error'}
            min="1"
            disabled={infiniteCycles}
            {...register('numberOfCycles', { value: programSet ? programSet?.numberOfCycles : 1 })}
          />
          {errors.numberOfCycles && <InputError message={errors.numberOfCycles.message} />}
        </div>

        <div className="ml-4 flex items-start">
          <LightSwitch
            wrapperClassNames="mt-14"
            label="Infinite Cycles"
            onToggle={(name, value) => {
              setValue(name, value)

              const numberOfCycles = value ? null : 1
              setValue('numberOfCycles', numberOfCycles, { shouldValidate: true })
              setValue('intervalBetweenCyclesHours', null, { shouldValidate: true })
              setValue('intervalBetweenCyclesMinutes', null, { shouldValidate: true })
              setValue('intervalBetweenCyclesSeconds', null, { shouldValidate: true })
            }}
            defaultState={getBooleanValue(programSet, 'infiniteCycles', false)}
            {...register('infiniteCycles', { value: getBooleanValue(programSet, 'infiniteCycles', false) })}
          />
        </div>
      </div>

      <div className={classNames('col-12 col-md-6', { hidden: (numberOfCycles == 1 || !numberOfCycles || infiniteCycles) })}>
        <InputLabel>
          Interval between cycles
          <RequiredAsterisk />
        </InputLabel>

        <GroupedInputs>
          <Input
            placeholder="hh"
            maxlength="2"
            type="number"
            width="60px"
            textCenter
            className={errors.intervalBetweenCyclesHours && 'error'}
            {...register('intervalBetweenCyclesHours', { value: times?.intervalBetweenCycles[0] || '' })}
          />

          <TimeSeperator>
            :
          </TimeSeperator>

          <Input
            placeholder="mm"
            maxlength="2"
            type="number"
            width="60px"
            textCenter
            className={errors.intervalBetweenCyclesMinutes && 'error'}
            {...register('intervalBetweenCyclesMinutes', { value: times?.intervalBetweenCycles[1] || '' })}
          />

          <TimeSeperator>
            :
          </TimeSeperator>

          <Input
            placeholder="ss"
            maxlength="2"
            type="number"
            width="60px"
            textCenter
            className={errors.intervalBetweenCyclesSeconds && 'error'}
            {...register('intervalBetweenCyclesSeconds', { value: times?.intervalBetweenCycles[2] || '' })}
          />
        </GroupedInputs>

        {
          (
            (
              errors.intervalBetweenCyclesHours?.type === 'min' ||
              errors.intervalBetweenCyclesHours?.type === 'max' ||
              errors.intervalBetweenCyclesMinutes?.type === 'min' ||
              errors.intervalBetweenCyclesMinutes?.type === 'max' ||
              errors.intervalBetweenCyclesSeconds?.type === 'min' ||
              errors.intervalBetweenCyclesSeconds?.type === 'max'
            ) &&
            <InputError message="Interval between cycles should be between 00:00 and 23:59" />
          ) ||
          (
            (errors.intervalBetweenCyclesHours || errors.intervalBetweenCyclesMinutes || errors.intervalBetweenCyclesSeconds) &&
            <InputError message="Interval between cycles is a required field" />
          )
        }
      </div>
    </>
  )
}
