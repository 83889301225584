import classNames from 'classnames'
import { each, filter, find, flatMap, get, groupBy, includes, isEmpty, map, some, times } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { Anchor } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'
import Tabs from '@/Components/Tabs'
import { rejectExclusions } from '@/Utilities/Form/Formatter'
import { matchPattern } from '@/Utilities/String'

const andOrTabs = [{
  title: 'And',
  key: 'and',
}, {
  title: 'Or',
  key: 'or',
}]

const stateOptions = [{
  value: '1',
  label: 'On',
}, {
  value: '0',
  label: 'Off',
}]

export default function Conditions(props) {
  const {
    setValue,
    control,
    register,
    errors,
    watch,
    fields,
    append,
    move,
    remove,
  } = useFormContext()

  const { updateConditionCount } = props

  const formValues = watch('conditionRules')

  let conditionRules
  const isEditing = get(props, 'data.isEditing', false)

  if (get(props, 'data.condition', false)) {
    conditionRules = get(props, 'data.conditionRules', [])
  }

  const operators = useCallback((type) => {
    let operators = [
      {
        value: 'lte',
        label: 'Less than or equal to',
        excludes: ['inputOutput.digitalOutput*', 'inputOutput.digitalInput'],
      },
      {
        value: 'gte',
        label: 'Greater than or equal to',
        excludes: ['inputOutput.digitalOutput*', 'inputOutput.digitalInput'],
      },
      {
        value: 'lt',
        label: 'Less than',
        excludes: ['inputOutput.digitalOutput*', 'inputOutput.digitalInput'],
      },
      {
        value: 'gt',
        label: 'Greater than',
        excludes: ['inputOutput.digitalOutput*', 'inputOutput.digitalInput'],
      },
      {
        value: 'ne',
        label: 'Not equal to',
        excludes: ['inputOutput.digitalOutput*', 'inputOutput.digitalInput'],
      },
      {
        value: 'eq',
        label: 'Equal to',
        excludes: [],
      },
    ]

    operators = rejectExclusions(operators, type)

    return operators
  }, [])

  const valueType = useCallback((type) => {
    const selectTypes = ['inputOutput.digitalOutput*', 'inputOutput.digitalInput']

    if (some(selectTypes, (pattern) => {
      return matchPattern(pattern, type)
    })) {
      return 'select'
    }

    return 'number'
  }, [])

  const elements = useMemo(() => {
    let elementGroups = groupBy(props.allInputOutputs, 'detailsType')

    each(elementGroups, (group, index) => {
      elementGroups[index] = map(group, (element) => {
        return {
          value: element.id,
          label: element.name,
        }
      })
    })

    return elementGroups
  }, [props.allInputOutputs])

  const propertyDepthOptions = useMemo(() => {
    const maxDepth = 16

    return map(times(maxDepth, Number), (depth) => {
      return {
        value: depth + 1,
        label: `Depth ${depth + 1}`,
      }
    })
  }, [])

  const getPropertyDepthOptions = useCallback((selectedInputOutput) => {
    const id = get(selectedInputOutput, 'value')

    if (id && !isEmpty(props.allInputOutputs)) {
      const inputOutput = find(props.allInputOutputs, ['id', id])
      const depths = get(inputOutput, 'details.manufacturerModel.configuration.depths')

      if (depths) {
        const validPropertyDepthOptions = filter(propertyDepthOptions, (option) => {
          return option.value <= depths
        })

        return validPropertyDepthOptions
      }
    }

    return []
  }, [propertyDepthOptions, props.allInputOutputs])

  useEffect(() => {
    if (isEditing && conditionRules && !isEmpty(elements)) {
      each(conditionRules, (condition, index) => {
        const inputOutputs = elements[get(conditionRules, `${index}.elementType`)]
        const elementTypes = flatMap(props.inputOutputTypeOptions, (type) => {
          if (isEmpty(type.options)) {
            return type
          }

          return type.options
        })

        if (fields.length < index + 1) {
          const inputOutputElementId = get(conditionRules, `${index}.inputOutputElementId`)
          const operator = get(conditionRules, `${index}.operator`)
          const propertyDepth = get(conditionRules, `${index}.propertyDepth`)
          const elementType = get(conditionRules, `${index}.elementType`)
          let value = get(conditionRules, `${index}.value`)

          if (valueType(elementType) == 'select') {
            value = find(stateOptions, ['value', value])
          }

          const validPropertyDepths = getPropertyDepthOptions(find(inputOutputs, ['value', inputOutputElementId]))

          append({
            elementType: find(elementTypes, ['value', elementType]),
            inputOutputElementId: find(inputOutputs, ['value', inputOutputElementId]),
            operator: find(operators(elementType), ['value', operator]),
            value: value,
            propertyDepth: find(validPropertyDepths, ['value', propertyDepth]),
            trailingLogicalOperator: '',
          }, { focusName: 'conditionRules' })
        }
      })
    }
  }, [elements])

  useEffect(() => {
    if (fields) {
      updateConditionCount(fields.length)
    }
  }, [fields])

  return (
    <>
      {map(fields, (field, index, array) => {
        return (
          <div className="relative mt-5 flex rounded border p-5" key={field.id}>
            <div className="flex justify-end">
              <div
                className="absolute -right-3 -top-3 cursor-pointer border-slate-100 bg-white hover:text-red-500"
                onClick={() => {
                  return remove(index)
                }}
              >
                <i className="fa-sharp fa-solid fa-circle-xmark fa-xl"></i>
              </div>
            </div>
            <div className="flex w-1/12 flex-col justify-center">
              {index > 0 && (
                <div
                  className="mr-8 mb-2 flex h-12 items-center justify-center rounded border hover:cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    move(index, index - 1)
                  }}
                >
                  <i className="fa-regular fa-arrow-up fa-lg"></i>
                </div>
              )}

              {index + 1 !== array.length && (
                <div
                  className="mr-8 mb-2 flex h-12 items-center justify-center rounded border hover:cursor-pointer hover:bg-gray-100"
                  onClick={() => {
                    move(index, index + 1)
                  }}
                >
                  <i className="fa-regular fa-arrow-down fa-lg"></i>
                </div>
              )}
            </div>

            <div className="w-11/12">
              <div className="grid grid-cols-3 gap-3">
                <div>
                  <Controller
                    control={control}
                    name={`conditionRules.${index}.elementType`}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          label="Element type"
                          isRequired={true}
                          isSearchable={true}
                          options={props.inputOutputTypeOptions}
                          hasError={!!errors.conditionRules?.[index]?.elementType}
                          afterChange={() => {
                            setValue(`conditionRules.${index}.inputOutputElementId`, '')
                            setValue(`conditionRules.${index}.operator`, '')
                            setValue(`conditionRules.${index}.value`, '')
                            setValue(`conditionRules.${index}.propertyDepth`, '')
                          }}
                        />
                      )
                    }}
                  />

                  {errors.conditionRules?.[index]?.elementType && <InputError message={errors.conditionRules?.[index]?.elementType.message} />}
                </div>

                <div>
                  <Controller
                    control={control}
                    name={`conditionRules.${index}.inputOutputElementId`}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          label="Element"
                          isRequired={true}
                          isSearchable={true}
                          noOptionsMessage={() => {
                            return !formValues[index]?.elementType?.value ? 'Select an element type first.' : 'No options.'
                          }}
                          options={elements[formValues[index]?.elementType?.value]}
                          hasError={!!errors.conditionRules?.[index]?.inputOutputElementId}
                        />
                      )
                    }}
                  />

                  {errors.conditionRules?.[index]?.inputOutputElementId && <InputError message={errors.conditionRules?.[index]?.inputOutputElementId.message} />}
                </div>

                <div>
                  <Select
                    label="Property"
                    defaultValue={{
                      value: 'currentValue',
                      label: 'Current value',
                    }}
                    options={[{
                      value: 'currentValue',
                      label: 'Current value',
                    }]}
                    isDisabled={true}
                  />
                </div>

                <div
                  className={classNames({ hidden: isEmpty(getPropertyDepthOptions(formValues[index]?.inputOutputElementId)) })}
                >
                  <Controller
                    control={control}
                    name={`conditionRules.${index}.propertyDepth`}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          label="Depth"
                          isRequired={true}
                          isSearchable={true}
                          noOptionsMessage={() => {
                            return !formValues[index]?.elementType?.value ? 'Select an element type first.' : 'No options.'
                          }}
                          options={getPropertyDepthOptions(formValues[index]?.inputOutputElementId)}
                          hasError={!!errors.conditionRules?.[index]?.propertyDepth}
                        />
                      )
                    }}
                  />

                  {errors.conditionRules?.[index]?.propertyDepth && <InputError message={errors.conditionRules?.[index]?.propertyDepth.message} />}
                </div>

                <div>
                  <Controller
                    control={control}
                    name={`conditionRules.${index}.operator`}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          label="Operator"
                          isSearchable={true}
                          isRequired={true}
                          noOptionsMessage={() => {
                            return !formValues[index]?.elementType?.value ? 'Select an element type first.' : 'No options.'
                          }}
                          options={formValues[index]?.elementType?.value ? operators(formValues[index]?.elementType?.value) : null}
                          hasError={!!errors.conditionRules?.[index]?.operator}
                        />
                      )
                    }}
                  />

                  {errors.conditionRules?.[index]?.operator && <InputError message={errors.conditionRules?.[index]?.operator.message} />}
                </div>

                <div>
                  {valueType(formValues[index]?.elementType?.value) == 'select' ? (
                    <Controller
                      control={control}
                      name={`conditionRules.${index}.value`}
                      render={({ field }) => {
                        return (
                          <Select
                            {...field}
                            label="Value"
                            isRequired={true}
                            isSearchable={true}
                            options={stateOptions}
                            hasError={!!errors.conditionRules?.[index]?.value}
                          />
                        )
                      }}
                    />
                  ): null}

                  {valueType(formValues[index]?.elementType?.value) == 'number' ? (
                    <Input
                      label={(
                        <>
                          Value

                          <span className={classNames({ hidden: !includes(formValues[index]?.elementType?.value, 'FlowMeter') }, 'ml-1 text-slate-500')}>
                            (Flow rate)
                          </span>
                        </>
                      )}
                      isRequired={true}
                      type="number"
                      className={errors.conditionRules?.[index]?.value && 'error'}
                      {...register(`conditionRules.${index}.value`, { value: conditionRules?.[index]?.value || '' })}
                    />
                  ): null}

                  {errors.conditionRules?.[index]?.value && <InputError message={errors.conditionRules?.[index]?.value.message} />}
                </div>
              </div>

              {(array.length > 1 && index !== array.length - 1) && (
                <div className="mt-4 inline-flex">
                  <Tabs
                    currentlySelected={formValues ? formValues[index]?.trailingLogicalOperator : null}
                    defaultTab={get(!isEmpty(conditionRules) ? conditionRules : formValues, `${index}.trailingLogicalOperator`, null)}
                    tabs={andOrTabs}
                    onChange={(value) => {
                      setValue(`conditionRules.${index}.trailingLogicalOperator`, value, { shouldValidate: true })
                    }}
                    className="inline-flex"
                  />
                  <RequiredAsterisk />

                  <div>
                    {errors.conditionRules?.[index]?.trailingLogicalOperator && <InputError message={errors.conditionRules?.[index]?.trailingLogicalOperator.message} />}
                  </div>
                </div>
              )}

              {(isEditing && conditionRules[index]?.conditionId) && (
                <Input
                  type="hidden"
                  {...register(`conditionRules.${index}.conditionId`, { value: conditionRules[index]?.conditionId || '' })}
                />
              )}
            </div>
          </div>
        )
      })}

      {fields?.length < 8 && (
        <div
          className="mt-6 inline-block hover:cursor-pointer"
          onClick={() => {
            append({
              elementType: '',
              inputOutputElementId: '',
              operator: '',
              value: '',
              propertyDepth: '',
              trailingLogicalOperator: '',
            }, { focusName: 'conditionRules' })
          }}
        >
          <Anchor className="transparent mt-2">
            <i className="fa-solid fa-plus"></i> {fields.length ? 'Add another condition' : 'Add condition' }
          </Anchor>
        </div>
      )}
    </>
  )
}
