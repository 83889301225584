import Tippy from '@tippyjs/react'
import { endsWith, get, includes, isEmpty, isObject, isUndefined, map, startCase } from 'lodash-es'
import { useParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import AlertContent from '@/Components/alerts/AlertContent'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import Card from '@/Components/Card'
import { getDisabledWithReason } from '@/Components/common/AssociationDisabledReason'
import DropdownList from '@/Components/DropdownList'
import DayPicker from '@/Components/form/DayPicker'
import Map from '@/Components/Map'
import { modalState } from '@/Config/Atoms/General'
import { abbreviationToText } from '@/Utilities/Units'
import useAuth from '@/Utilities/useAuth'
import useEntityMonitor from '@/Utilities/useEntityMonitor'

const CardRow = (props) => {
  return <div className="grid grid-cols-2 gap-4 text-sm">
    {props.children}
  </div>
}

const CardRowTitle = (props) => {
  return <div className="text-sm">
    {props.children}
  </div>
}

const CardRowContent = (props) => {
  return <div className="text-sm">
    {props.children}
  </div>
}

const MapContainer = styled.div`
  height: calc(100vh - 265px);
`

export default function General(props) {
  const auth = useAuth()
  const setModal = useSetRecoilState(modalState)
  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  const urlParams = useParams()

  const {
    program,
    getProgram,
    markers,
    programSite,
  } = props

  const preventSavingOnProgram = isInRunningState('program', program?.id)
  const runningProgramNamesForProgram = getRelatedProgramNames('program', program?.id)

  const preventSavingOnProgramSet = isInRunningState('programSet', program?.programSet?.id)
  const runningProgramNamesForProgramSet = getRelatedProgramNames('programSet', program?.programSet?.id)

  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 @lg:col-span-4 @2xl:col-span-3">
        {!isEmpty(program) && !isUndefined(program.active) && !program.active && (
          <AlertContent title={`Program "${program.name}" has not been setup yet`} hideIcon={false} type="warning" className="mb-5">
            <p className="mb-2">
              FutureOps has not received a response from the responsible FEP.
              You'll be able to use this site once setup is confirmed.
            </p>

            <p>
              If this issue persists, please try <a className="cursor-pointer underline" onClick={reloadPage}>refreshing</a> or contact our support team.
            </p>
          </AlertContent>
        )}
        <div className="grid grid-cols-1 gap-4 @md:grid-cols-2 @lg:grid-cols-1">
          <Card
            title={`Program Set: ${program.programSet.name}`}
            className="mb-0"
            collapsible={true}
            actions={
              <PermissionGuard permission="update-program" auth={auth}>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[{
                    label: 'Edit program set',
                    disabled: !auth.can('update-program'),
                    disabledWithReason: preventSavingOnProgramSet && getDisabledWithReason(runningProgramNamesForProgramSet),
                    onClick: () => {
                      setModal({
                        name: 'set',
                        data: {
                          programSet: program.programSet,
                          site: programSite,
                          isEditing: true,
                          onSave: () => {
                            getProgram()
                          },
                        },
                      })
                    },
                  }]}
                />
              </PermissionGuard>
            }
          >
            {map(program.programSet, (value, key) => {
              if (
                (!isObject(value) && value) &&
                !includes([
                  'id',
                  'uuid',
                  'siteId',
                  'createdAt',
                  'updatedAt',
                  'stopTimeDisabled',
                ], key)
                && !endsWith(key, 'UnitOfMeasurement')
              ) {
                if (key === 'stopTime' && program.programSet.stopTimeDisabled) {
                  return <CardRow key={key}>
                    <CardRowTitle>{startCase(key)}</CardRowTitle>
                    <CardRowContent>Disabled</CardRowContent>
                  </CardRow>
                } else if (program.programSet[`${key}UnitOfMeasurement`] || includes(['irrigationBasis', 'scheduleType'], key)) {
                  return <CardRow key={key}>
                    <CardRowTitle>{startCase(key)}</CardRowTitle>
                    <CardRowContent>{startCase(value)} {program.programSet[`${key}UnitOfMeasurement`] ? abbreviationToText(program.programSet[`${key}UnitOfMeasurement`]) : null}</CardRowContent>
                  </CardRow>
                } else {
                  return <CardRow key={key}>
                    <CardRowTitle>{startCase(key)}</CardRowTitle>
                    <CardRowContent>{value}</CardRowContent>
                  </CardRow>
                }
              } else if (key === 'schedule') {
                return <CardRow key={key}>
                  <CardRowTitle>{startCase(key)}</CardRowTitle>
                  <CardRowContent>
                    <div className="text-primary hover:cursor-pointer">
                      <Tippy
                        content={
                          <div className="flex">
                            <DayPicker
                              initialSchedule={value || null}
                              indexCount={get(program, 'programSet.schedule.length', 1)}
                              noBorder={true}
                              displayOnly={true}
                            />
                          </div>
                        }
                        delay={200}
                        theme="light"
                        placement="right"
                      >
                        <div className="mr-2 inline-block">
                          View schedule
                        </div>
                      </Tippy>
                    </div>
                  </CardRowContent>
                </CardRow>
              }
            })}
          </Card>

          <Card
            title={`Program: ${program.name}`}
            className="mb-0"
            actions={
              <PermissionGuard anyOf={['update-program', 'delete-program']} auth={auth}>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[{
                    label: 'Edit program',
                    disabled: !auth.can('update-program'),
                    disabledWithReason: preventSavingOnProgram && getDisabledWithReason(runningProgramNamesForProgram),
                    onClick: () => {
                      setModal({
                        name: 'program',
                        data: {
                          program: program,
                          isEditing: true,
                          onSave: () => {
                            getProgram()
                          },
                        },
                      })
                    },
                  }, {
                    label: 'Delete program',
                    disabled: !auth.can('delete-program'),
                    disabledWithReason: preventSavingOnProgram && getDisabledWithReason(runningProgramNamesForProgram),
                    onClick: () => {
                      setModal({
                        name: 'warning',
                        data: {
                          title: 'Delete program',
                          content: `Are you sure you want to delete ${program.name}? This will delete all hardware and alarms attached to it. This may also affect reporting. This action cannot be undone.`,
                          endpoint: `/program/delete/${urlParams.id}`,
                          successFlashMessage: `${program.name} deleted successfully.`,
                          redirect: '/programs',
                          savePreventionState: {
                            model: 'program',
                            id: urlParams.id,
                            pendingChanges: program.pendingChanges,
                          },
                        },
                      })
                    },
                  }]}
                />
              </PermissionGuard>
            }
          >
            {program.description ? (
              <CardRow>
                <CardRowTitle>Description</CardRowTitle>
                <CardRowContent>{program.description}</CardRowContent>
              </CardRow>
            ) : null}

            <CardRow>
              <CardRowTitle>Program Set</CardRowTitle>
              <CardRowContent>{program.programSet.name}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>RTU Program Number</CardRowTitle>
              <CardRowContent>{program.rtuProgramNumber}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Main Line</CardRowTitle>
              <CardRowContent>{program.mainLine?.name || 'N/A'}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Communication Failure</CardRowTitle>
              <CardRowContent>{startCase(program.actionOnCommunicationFailure)}</CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Expected Flow</CardRowTitle>
              <CardRowContent>
                {program.expectedFlowDisabled == 1 ? 'Disabled' : `${program.expectedFlow} ${program.expectedFlowUnitOfMeasurement}`}
              </CardRowContent>
            </CardRow>

            {program.expectedFlowDisabled != 1 && (
              <>
                <CardRow>
                  <CardRowTitle>Low Tolerance</CardRowTitle>
                  <CardRowContent>
                    {program.lowTolerancePercentage}%
                  </CardRowContent>
                </CardRow>

                <CardRow>
                  <CardRowTitle>High Tolerance</CardRowTitle>
                  <CardRowContent>
                    {program.highTolerancePercentage}%
                  </CardRowContent>
                </CardRow>
              </>
            )}

            {program.programSet.irrigationBasis === 'time' && (
              <CardRow>
                <CardRowTitle>Runtime</CardRowTitle>
                <CardRowContent>
                  {program.runtime}
                </CardRowContent>
              </CardRow>
            )}

            {program.programSet.irrigationBasis === 'quantity' && (
              <CardRow>
                <CardRowTitle>Water Quantity</CardRowTitle>
                <CardRowContent>
                  {program.waterQuantity} {abbreviationToText(program.waterQuantityUnitOfMeasurement)}
                </CardRowContent>
              </CardRow>
            )}

            <CardRow>
              <CardRowTitle>Reporting</CardRowTitle>
              <CardRowContent>
                {program.changeOfState == 1 ? 'Enabled' : 'Disabled'}
              </CardRowContent>
            </CardRow>

            <CardRow>
              <CardRowTitle>Wait program end due to outputs close delay</CardRowTitle>
              <CardRowContent>
                {program.awaitOutputsClose == 1 ? 'Enabled' : 'Disabled'}
              </CardRowContent>
            </CardRow>
          </Card>
        </div>
      </div>

      <MapContainer className="sticky top-6 col-span-12 flex justify-center @lg:col-span-8 @2xl:col-span-9">
        <Map center={props.siteArea} markers={markers} />
      </MapContainer>
    </div>
  )
}
