import * as Sentry from '@sentry/react'
import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { debounce, map } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'

import ActionIcon from '@/Components/ActionIcon'
import AlarmIcon from '@/Components/alerts/AlarmIcon'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import { getDisabledWithReason } from '@/Components/common/AssociationDisabledReason'
import SearchInput from '@/Components/common/input/SearchInput'
import TableHeader from '@/Components/common/table/Header'
import DropdownList from '@/Components/DropdownList'
import SkeletonTable from '@/Components/SkeletonTable'
import TableBasic from '@/Components/tables/base/Basic'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import { standardActions, subscribe, unsubscribe } from '@/Utilities/Events'
import { formatKeys } from '@/Utilities/Form/Formatter'
import { getPaginationMeta } from '@/Utilities/Pagination'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useEventSubscriber from '@/Utilities/useEventSubscriber'

function ConditionsAll() {
  // Utilities
  const auth = useAuth()
  const navigate = useNavigate()
  const apiClient = useApiClient()

  // Internal component state
  const [lastQuery, setLastQuery] = useState({})
  const [tableData, setTableData] = useState(null)
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [tableSearchTerm, setTableSearchTerm] = useState({
    type: null,
    value: null,
  })
  const setAlert = useSetRecoilState(pageAlertState)
  const setModal = useSetRecoilState(modalState)

  // Events
  useEventSubscriber(['condition'], standardActions, () => {
    updateTable()
  })

  const updateTable = useCallback(() => {
    getTableData(getPaginationMeta(lastQuery))
  }, [lastQuery])

  const getTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      const currentQuery = {
        pageIndex,
        pageSize,
        filters,
      }

      setLastQuery(currentQuery)

      const query = new URLSearchParams([
        ['page', pageIndex + 1],
        ['pageSize', pageSize],
        ['searchType', filters?.search?.type || ''],
        ['search', filters?.search?.value || ''],
        ['with[]', 'site'],
      ])

      try {
        let { data } = await apiClient.get(`/condition/query?${query}`)
        data = formatKeys(data, 'camel')

        let conditionData = map(data.conditions.data, (condition) => {
          return {
            name: (
              <div className="flex items-center">
                <Tippy content={`${condition.active ? 'Successfully' : 'Not yet'} activated with the FEP`} theme="light" placement="right">
                  <span className="mr-4 p-1">
                    <span className={classNames({
                      'bg-green-600': condition.active,
                      'bg-yellow-500': !condition.active,
                    }, 'w-3 h-3 inline-block rounded-full')}></span>
                  </span>
                </Tippy>
                {condition.name}
                {condition.hasAlarms && (
                  <AlarmIcon type="condition" uuid={condition.uuid} />
                )}
              </div>
            ),
            site: condition.site.name,
            onStabilityTime: condition.onStabilityTime,
            offStabilityTime: condition.offStabilityTime,
            changeOfState: condition.changeOfState ? 'Enabled' : 'Disabled',
            recordHistory: condition.recordHistory ? 'Enabled' : 'Disabled',
            action: (
              <PermissionGuard anyOf={['update-condition', 'delete-condition']} auth={auth}>
                <DropdownList
                  icon={<ActionIcon />}
                  options={[{
                    label: 'Edit condition',
                    disabled: !auth.can('update-condition'),
                    onClick: () => {
                      setModal({
                        name: 'condition',
                        data: {
                          condition: condition,
                          isEditing: true,
                          onSave: () => {
                            getTableData(currentQuery)
                          },
                        },
                      })
                    },
                  }, {
                    label: 'Delete condition',
                    disabled: !auth.can('delete-condition'),
                    disabledWithReason: getDisabledWithReason(null, condition.currentAssociations),
                    onClick: () => {
                      setModal({
                        name: 'warning',
                        data: {
                          title: 'Delete condition',
                          content: `Please note that ${condition.name} will be permanently removed from the system.`,
                          endpoint: `/condition/delete/${condition.id}`,
                          successFlashMessage: `${condition.name} deleted successfully.`,
                          onComplete: async () => {
                            getTableData(currentQuery)
                            setModal(null)
                          },
                          savePreventionState: {
                            model: 'condition',
                            id: condition.id,
                          },
                        },
                      })
                    },
                  }]}
                />
              </PermissionGuard>
            ),
          }
        })

        let tableData = {
          ...data.conditions,
          data: conditionData,
        }

        setTableData(tableData)
        setTableDataLoading(false)
      } catch (error) {
        Sentry.captureException(error)
      }
    }, 250)
  }, [
    setTableData,
    setTableDataLoading,
    navigate,
    setModal,
    setAlert,
  ])

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0,
    })
  }, [getTableData])

  const getTableDataStart = useCallback((params) => {
    setTableDataLoading(true)
    getTableData(getPaginationMeta(params))
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: 'Name',
          accessor: 'name',
          width: '20%',
        },
        {
          Header: 'Site',
          accessor: 'site',
          width: '15%',
        },
        {
          Header: 'On stability Time',
          accessor: 'onStabilityTime',
          width: '15%',
        },
        {
          Header: 'Off stability Time',
          accessor: 'offStabilityTime',
          width: '15%',
        },
        {
          Header: 'Reporting',
          accessor: 'changeOfState',
          width: '15%',
        },
        {
          Header: 'Record history',
          accessor: 'recordHistory',
          width: '15%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '5%',
          style: { textAlign: 'right' },
        },
      ]
    }, [],
  )

  useEffect(() => {
    let conditionCreated = () => {
      getTableDataStart(lastQuery)
    }

    if (lastQuery) {
      subscribe('conditionCreated', conditionCreated)
    }

    return () => {
      unsubscribe('conditionCreated', conditionCreated)
    }
  }, [lastQuery, getTableDataStart])

  return (
    <>
      {
        tableData ?
          <TableBasic
            testId="tableConditionsAll"
            columns={tableColumns}
            data={tableData}
            getTableData={getTableDataStart}
            header={
              <TableHeader
                testId="tableConditionsAllTableHeader"
                className="grid-cols-2-right-auto"
              >
                <TableHeader.Title>Conditions</TableHeader.Title>

                <TableHeader.Toolbar className="hidden md:block">
                  <SearchInput
                    className="block w-full flex-wrap"
                    term={tableSearchTerm}
                    onTermUpdate={(value) => {
                      return setTableSearchTerm({
                        ...tableSearchTerm,
                        value,
                      })
                    }
                    }
                  />
                </TableHeader.Toolbar>

                <TableHeader.SubHeader className="flex flex-col justify-between gap-3 @lg:flex-row @lg:items-center md:hidden">
                  <SearchInput
                    className="flex w-full flex-wrap"
                    term={tableSearchTerm}
                    onTermUpdate={(value) => {
                      return setTableSearchTerm({
                        ...tableSearchTerm,
                        value,
                      })
                    }
                    }
                  />
                </TableHeader.SubHeader>
              </TableHeader>
            }
            loading={tableDataLoading}
            topSearch
            searchTerm={tableSearchTerm}
          /> : <SkeletonTable />
      }
    </>
  )
}

export default ConditionsAll
