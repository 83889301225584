import { map, replace, startCase } from 'lodash-es'
import { useCallback } from 'react'

const conditionTypes = [
  'start',
  'wait',
  'stop',
]

const placeholder = (
  <div className="text-slate-300">
    &#8212;
  </div>
)

export default function Conditions(props) {
  const { program } = props

  const conditionIdTitle = useCallback((type) => {
    const conditionType = program[`${type}ConditionType`]
    switch (conditionType) {
      case 'condition.digitalInput':
        return 'Digital input'

      case 'condition.digitalOutput':
        return 'Digital output'

      case 'condition':
        return 'Condition'

      default:
        return 'ID'
    }
  }, [program])

  return (
    <div className="grid grid-cols-3 gap-3 text-sm">
      {
        map(conditionTypes, (conditionType) => {
          return (
            <div className="rounded border border-slate-200 bg-white shadow" key={conditionType}>
              <div className="border-b border-slate-200 p-6 text-lg">
                {startCase(conditionType)} condition
              </div>

              <div className="grid grid-cols-2 gap-6 p-6">
                <div>
                  Type
                </div>
                {program[`${conditionType}ConditionType`] ? (
                  <div className="text-slate-500">
                    {startCase(replace(program[`${conditionType}ConditionType`], 'condition.', ''))}
                  </div>
                ) : placeholder}

                <div>
                  {conditionIdTitle(conditionType)}
                </div>
                {program[`${conditionType}ConditionId`] ? (
                  <div className="text-slate-500">
                    {program[`${conditionType}Condition`].name}
                  </div>
                ) : placeholder}

                <div>
                  State
                </div>
                {program[`${conditionType}ConditionType`] ? (
                  <div className="text-slate-500">
                    {program[`${conditionType}ConditionState`] ? 'True' : 'False'}
                  </div>
                ) : placeholder}
              </div>
            </div>
          )
        })
      }
    </div>
  )
}
