import Tippy from '@tippyjs/react'
import { get } from 'lodash-es'
import { rem } from 'polished'
import { forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { twMerge } from 'tailwind-merge'

const StyledLightSwitch = styled.span`
  align-items: center;
  background: ${({ uncheckedColour }) => {
    return uncheckedColour || 'grey'
  }};
  border-radius: 100px;
  color: var(--text-dark);
  cursor: pointer;
  display: inline-block;
  font-size: ${rem(14)};
  height: 26px;
  margin-top: 0;
  position: relative;
  text-indent: 55px;
  white-space: nowrap;
  width: 46px;

  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 22px;
    height: 22px;
    background: #fff;
    border-radius: 30px;
    transition: 0.3s;
  }
`

const Input = styled.input`
  &[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  &.checked + span {
    background: ${({ checkedColour }) => {
    return checkedColour || 'var(--primary)'
  }};
  }

  &.checked + span:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`

const LightSwitch = forwardRef((props, ref) => {
  const [checked, setChecked] = useState(props.defaultState || false)
  const {
    name,
    onToggle,
    defaultValue,
    helpTooltip,
    wrapperClassNames,
    uncheckedColour,
    checkedColour,
  } = props

  useEffect(() => {
    if (defaultValue) {
      setChecked(defaultValue)
    }
  }, [defaultValue])

  return (
    <>
      <div className={twMerge('mt-4 flex items-center', wrapperClassNames)}>
        <div className="user-select-none mr-2 flex cursor-pointer items-center"
          onClick={() => {
            if (checked === false) {
              setChecked(true)
              onToggle(name, true)
            } else {
              setChecked(false)
              onToggle(name, false)
            }
          }}
        >
          {get(props, 'leftLabel') && (
            <span className="mr-3 inline-block text-sm text-slate-700">
              {props.leftLabel}
            </span>
          )}

          <Input
            type="hidden"
            id={name}
            className={checked === true ? 'checked' : ''}
            {...props}
            ref={ref}
          />

          <StyledLightSwitch
            htmlFor={name}
            uncheckedColour={uncheckedColour}
            checkedColour={checkedColour}
          />

          <span className="ml-3 inline-block text-sm text-slate-700">
            {props.label}
          </span>
        </div>
        <div>
          {helpTooltip && (
            <Tippy content={helpTooltip} disabled={helpTooltip ? false : true} zIndex="99999" theme="light" placement="top" delay={200}>
              <i className="fa-solid fa-circle-question cursor-pointer p-1"></i>
            </Tippy>
          )}
        </div>
      </div>
    </>
  )
})

export default LightSwitch
