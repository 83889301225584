import { endsWith, isEmpty, isUndefined, map, startCase } from 'lodash-es'
import { rem } from 'polished'
import { useMemo } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import AlertContent from '@/Components/alerts/AlertContent'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import { getDisabledWithReason } from '@/Components/common/AssociationDisabledReason'
import DropdownList from '@/Components/DropdownList'
import { modalState } from '@/Config/Atoms/General'
import { extractAttributes } from '@/Utilities/inputOutput'
import { abbreviationToText } from '@/Utilities/Units'
import useAuth from '@/Utilities/useAuth'
import useEntityMonitor from '@/Utilities/useEntityMonitor'

const Card = styled.div`
  border: 1px solid #E4E7EC;
  border-radius: 5px;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
  width: 100%;
  margin-top: 15px;
  background: #ffffff;

  &:first-of-type {
    margin-top: 0;
  }
`

const CardDetails = styled.div`
  margin: 15px 0;
`

const CardTitle = styled.div`
  align-items: center;
  border-bottom: 1px solid #E4E7EC;
  color: var(--heading-color);
  display: flex;
  justify-content: space-between;
  font-size: ${rem(18)};
  padding: 14px 24px;

  .more-actions {
    align-items: center;
    display: flex;
    position: relative;

    svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
`

const CardRow = styled.div`
  display: flex;
  font-size: ${rem(14)};
  justify-content: space-between;
  padding: 6px 24px;
`

const CardRowTitle = styled.div`
  align-items: center;
  color: var(--heading-color);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
  margin-right: 5px;
`

const CardRowContent = styled.div`
  color: var(--text-light);
  display: flex;
  font-size: ${rem(14)};
  flex-basis: 50%;
`

const InputOutputName = styled.div`
  text-transform: capitalize;
`

function InputOutputManageDetailsCard(props) {
  const auth = useAuth()
  const navigate = useNavigate()
  const setModal = useSetRecoilState(modalState)
  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  const {
    manageCoordinates,
    inputOutput,
  } = props

  const attributes = useMemo(() => {
    return extractAttributes(inputOutput)
  }, [inputOutput])

  // TODO: Make useCallback or useMemo as appropriate
  const mainCardOptions = () => {
    const preventSaving = isInRunningState('inputOutput', inputOutput?.id)
    const runningProgramNames = getRelatedProgramNames('inputOutput', inputOutput?.id)

    if (manageCoordinates) {
      return [{
        label: 'Stop managing coordinates',
        disabled: !auth.can('update-input-output'),
        onClick: () => {
          navigate(`/io/manage/${inputOutput.id}`)
        },
      }]
    }

    return [
      {
        label: 'Manage coordinates',
        disabled: !auth.can('update-input-output'),
        onClick: () => {
          navigate(`/io/manage/${inputOutput.id}/coordinates`)
        },
      },
      {
        label: 'Edit I/O',
        disabled: !auth.can('update-input-output'),
        disabledWithReason: preventSaving && getDisabledWithReason(runningProgramNames),
        topLine: true,
        onClick: () => {
          setModal({
            name: 'io',
            data: {
              io: inputOutput,
              site: inputOutput.site,
              isEditing: true,
            },
          })
        },
      },
      {
        label: 'Delete I/O',
        disabled: !auth.can('delete-input-output'),
        disabledWithReason: getDisabledWithReason(null, inputOutput.currentAssociations),
        onClick: () => {
          setModal({
            name: 'warning',
            data: {
              endpoint: `/input-output/delete/${inputOutput.id}`,
              title: 'Delete I/O',
              content: `Are you sure you want to delete ${inputOutput.name}? This action cannot be undone.`,
              successFlashMessage: 'I/O deleted successfully.',
              redirect: '/io',
              savePreventionState: {
                model: 'inputOutput',
                id: inputOutput.id,
              },
            },
          })
        },
      },
    ]
  }

  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <>
      {!isEmpty(inputOutput) && !isUndefined(inputOutput.active) && !inputOutput.active && (
        <AlertContent title={`I/O "${inputOutput.name}" has not been setup yet`} hideIcon={false} type="warning" className="mb-5">
          <p className="mb-2">
            FutureOps has not received a response from the responsible FEP.
            You'll be able to use this site once setup is confirmed.
          </p>

          <p>
            If this issue persists, please try <a className="cursor-pointer underline" onClick={reloadPage}>refreshing</a> or contact our support team.
          </p>
        </AlertContent>
      )}
      <Card>
        <CardTitle>
          <InputOutputName>
            {inputOutput.name}
          </InputOutputName>

          <div className="more-actions">
            <PermissionGuard anyOf={['update-input-output', 'delete-input-output']} auth={auth}>
              <DropdownList
                icon={<ActionIcon />}
                options={mainCardOptions()}
              />
            </PermissionGuard>
          </div>
        </CardTitle>

        <CardDetails>
          <CardRow>
            <CardRowTitle>ID</CardRowTitle>
            <CardRowContent>{inputOutput.id}</CardRowContent>
          </CardRow>

          <CardRow>
            <CardRowTitle>Site</CardRowTitle>
            <CardRowContent>
              <NavLink to={`/site/manage/${inputOutput?.site?.id}`} className="text-primary">
                {inputOutput?.site?.name}
              </NavLink>
            </CardRowContent>
          </CardRow>

          {inputOutput.description && (
            <CardRow>
              <CardRowTitle>Description</CardRowTitle>
              <CardRowContent>{inputOutput.description}</CardRowContent>
            </CardRow>
          )}

          {inputOutput.moduleSlot && (
            <CardRow>
              <CardRowTitle>Module Slot</CardRowTitle>
              <CardRowContent>{inputOutput.moduleSlot}</CardRowContent>
            </CardRow>
          )}

          {inputOutput.channel && (
            <CardRow>
              <CardRowTitle>Channel</CardRowTitle>
              <CardRowContent>{inputOutput.channel}</CardRowContent>
            </CardRow>
          )}

          <CardRow>
            <CardRowTitle>Reporting</CardRowTitle>
            <CardRowContent>{inputOutput.changeOfState ? 'Enabled' : 'Disabled'}</CardRowContent>
          </CardRow>

          <CardRow>
            <CardRowTitle>Auxiliary</CardRowTitle>
            <CardRowContent>{inputOutput.auxiliary ? 'Yes' : 'No'}</CardRowContent>
          </CardRow>

          {(props.coordinates?.lat && props.coordinates?.lng) && (
            <CardRow>
              <CardRowTitle>GPS Coordinates</CardRowTitle>
              <CardRowContent>{props.coordinates?.lat}, {props.coordinates?.lng}</CardRowContent>
            </CardRow>
          )}
        </CardDetails>
      </Card>

      {!manageCoordinates && (
        <>
          <Card>
            <CardTitle>
              <InputOutputName>
                Logging
              </InputOutputName>
            </CardTitle>

            {(
              inputOutput.logging ? (
                <CardDetails>
                  {inputOutput.loggingType === 'interval' && (
                    <>
                      <CardRow>
                        <CardRowTitle>Logging Interval</CardRowTitle>
                        <CardRowContent>{inputOutput.loggingInterval}</CardRowContent>
                      </CardRow>
                    </>
                  )}

                  {inputOutput.loggingType === 'time' && (
                    <CardRow>
                      <CardRowTitle>Logging Scheduled Time</CardRowTitle>
                      <CardRowContent>{inputOutput.loggingScheduledTime}</CardRowContent>
                    </CardRow>
                  )}

                  {inputOutput.loggingType === 'delta' && (
                    <>
                      <CardRow>
                        <CardRowTitle>Logging Delta</CardRowTitle>
                        <CardRowContent>{inputOutput.loggingDelta}</CardRowContent>
                      </CardRow>

                      <CardRow>
                        <CardRowTitle title="Logging Delta Unit of Measure">Logging Delta Unit of Meas.</CardRowTitle>
                        <CardRowContent>{abbreviationToText(inputOutput.loggingDeltaUnitOfMeasurement)}</CardRowContent>
                      </CardRow>
                    </>
                  )}
                </CardDetails>
              ) : (
                <CardDetails>
                  <CardRow>
                    <CardRowTitle>Logging disabled</CardRowTitle>
                  </CardRow>
                </CardDetails>
              )
            )}
          </Card>

          {!isEmpty(attributes) && (
            <Card>
              <CardTitle>
                <InputOutputName>
                  Details
                </InputOutputName>
              </CardTitle>

              <CardDetails>
                {map(attributes, (value, name) => {
                  if (endsWith(name, 'UnitOfMeasurement')) {
                    return null
                  }

                  if (name === 'scaleMin' || name === 'scaleMax') {
                    return <CardRow key={name}>
                      <CardRowTitle>{startCase(name)}</CardRowTitle>
                      <CardRowContent>{value} {inputOutput.details['scaleUnitOfMeasurement'] ? abbreviationToText(inputOutput.details['scaleUnitOfMeasurement']) : ''}</CardRowContent>
                    </CardRow>
                  }

                  return (
                    <CardRow key={name}>
                      <CardRowTitle>{startCase(name)}</CardRowTitle>
                      <CardRowContent>{value} {inputOutput.details[`${name}UnitOfMeasurement`] ? abbreviationToText(inputOutput.details[`${name}UnitOfMeasurement`]) : ''}</CardRowContent>
                    </CardRow>
                  )
                })}
              </CardDetails>
            </Card>
          )}
        </>
      )}
    </>
  )
}

export default InputOutputManageDetailsCard
