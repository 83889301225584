import { compact, concat, flatMap, get, includes, isEmpty, map, replace, some, sortBy, toLower } from 'lodash-es'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { getDisabledWithReason } from '@/Components/common/AssociationDisabledReason'
import Card from '@/Components/common/Card'
import InputOutputCardListItem from '@/Pages/site/overview/InputOutputCardListItem'
import useAuth from '@/Utilities/useAuth'

export default function InputOutputCard({
  site,
  displayedProgram,
  programActiveStatus,
  setModal,
  setAlert,
  updateDashboard,
  reportingStats,
}) {
  const auth = useAuth()
  const navigate = useNavigate()

  const [programInputOutputIds, fertIds] = useMemo(() => {
    const fertIds = flatMap(displayedProgram.fertigationPumps, (pump) => {
      return [pump.flowMeter?.id, pump.inputOutput?.id]
    })

    const inputOutputIds = flatMap(displayedProgram.inputOutputs, (inputOutput) => {
      return inputOutput.id
    })

    const mainLineIds = [get(displayedProgram, 'mainLine.mainValveId'), get(displayedProgram, 'mainLine.flowMeterId')]

    return [compact([
      ...fertIds,
      ...mainLineIds,
      ...inputOutputIds,
    ]), fertIds]
  }, [displayedProgram])

  const inputOutputs = useMemo(() => {
    if (isEmpty(site.inputOutputs)) {
      return []
    }

    const sortedInputOutputs = sortBy(
      site.inputOutputs,
      [
        (inputOutput) => {
          return isEmpty(inputOutput.activeAlarms)
        },
        (inputOutput) => {
          return !get(reportingStats, `${inputOutput.id}.changeOfState`, false)
        },
        (inputOutput) => {
          return get(displayedProgram, 'mainLine.mainValveId') !== inputOutput.id
        },
        (inputOutput) => {
          return get(displayedProgram, 'mainLine.flowMeterId') !== inputOutput.id
        },
        (inputOutput) => {
          return !some(get(displayedProgram, 'inputOutputs', []), { id: inputOutput.id })
        },
        (inputOutput) => {
          return !some(fertIds, (fertId) => {
            return inputOutput.id === fertId
          })
        },
      ],
    )

    return sortedInputOutputs
  }, [
    site.inputOutputs,
    displayedProgram,
    fertIds,
    reportingStats,
  ])

  return (
    <Card className="h-full">
      <Card.Header
        title="I/Os"
        className="p-3.5"
      >
      </Card.Header>

      <Card.Body className="flex">
        <div className="flex max-h-80 grow flex-col overflow-auto">
          {map(inputOutputs, (inputOutput) => {
            const type = replace(inputOutput.detailsType, 'inputOutput.', '')
            const inProgram = some(programInputOutputIds, (programInputOutputId) => {
              return programInputOutputId === inputOutput.id
            })
            const changeOfState = get(reportingStats, `${inputOutput.id}.changeOfState`, false)

            let stateChangeOptions = [
              {
                label: 'Edit I/O',
                disabled: !auth.can('update-input-output'),
                disabledWithReason: (programActiveStatus === 'running' && inProgram) ? getDisabledWithReason(displayedProgram.name) : false,
                topLine: true,
                onClick: () => {
                  setModal({
                    name: 'io',
                    data: {
                      io: inputOutput,
                      site: site,
                      isEditing: true,
                      onSave: () => {
                        updateDashboard()
                      },
                    },
                  })
                },
              },
              {
                label: 'Manage I/O',
                disabled: !auth.can('view-input-output'),
                onClick: () => {
                  navigate(`/io/manage/${inputOutput.id}`)
                },
              },
              {
                label: 'Delete I/O',
                topLine: true,
                disabled: !auth.can('delete-input-output'),
                disabledWithReason: (programActiveStatus === 'running' && inProgram) ? getDisabledWithReason(displayedProgram.name) : false,
                onClick: () => {
                  setModal({
                    name: 'warning',
                    data: {
                      endpoint: `/input-output/delete/${inputOutput.id}`,
                      title: 'Delete I/O',
                      content: `Are you sure you want to delete ${inputOutput.name}? This action cannot be undone.`,
                      successFlashMessage: 'I/O deleted successfully.',
                      onComplete: () => {
                        updateDashboard()
                      },
                      savePreventionState: {
                        model: 'inputOutput',
                        id: inputOutput.id,
                      },
                    },
                  })
                },
              },
            ]

            if (includes(toLower(type), 'output')) {
              stateChangeOptions = concat([{
                label: 'Manually start output',
                onClick: () => {
                  setModal({
                    name: 'warning',
                    data: {
                      endpoint: `/input-output/state/start/${inputOutput.id}`,
                      title: 'Start output',
                      content: 'This will start the output and require that you manually stop it.',
                      successFlashMessage: `Successfully queued ${inputOutput.name} to be started.`,
                      onComplete: () => {
                        setModal(null)
                      },
                      close: () => {
                        setModal(null)
                      },
                      onFailure: () => {
                        setModal(null)
                        setAlert({
                          type: 'error',
                          content: 'We were unable to process your request. Please try again.',
                        })
                      },
                    },
                  })
                },
                disabled: !auth.can('start-input-output'),
              }, {
                label: 'Manually stop output',
                onClick: () => {
                  setModal({
                    name: 'warning',
                    data: {
                      endpoint: `/input-output/state/stop/${inputOutput.id}`,
                      title: 'Stop output',
                      content: 'This will stop the output.',
                      successFlashMessage: `Successfully queued ${inputOutput.name} to be stopped.`,
                      onComplete: () => {
                        setModal(null)
                      },
                      close: () => {
                        setModal(null)
                      },
                      onFailure: () => {
                        setModal(null)
                        setAlert({
                          type: 'error',
                          content: 'We were unable to process your request. Please try again.',
                        })
                      },
                    },
                  })
                },
                disabled: !auth.can('stop-input-output'),
              }], stateChangeOptions)
            }

            return (
              <InputOutputCardListItem
                inputOutput={inputOutput}
                programActiveStatus={programActiveStatus}
                inProgram={inProgram}
                changeOfState={changeOfState}
                type={type}
                stateChangeOptions={stateChangeOptions}
                key={inputOutput.id}
              />
            )
          })}
        </div>
      </Card.Body>
    </Card>
  )
}
